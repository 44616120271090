import * as React from "react"
import { graphql } from "gatsby"

import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import styled from "styled-components"

import CatteryInfo from "../components/catteryinfo"
import Hero from "../components/hero"
import Parents from "../components/parents"

import SEO from "../components/seo"

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="Zucht | Leopardis" />
      <main style={{ background: "#FFFEFD" }}>
        <Hero />

        <CatteryInfo />

        <Parents parents={props.data.parentsData.edges} />
      </main>
    </Layout>
  )
}

export default IndexPage

export const getParents = graphql`
  query {
    parentsData: allContentfulParents {
      edges {
        node {
          augen
          charakter
          fell
          geschlecht
          gesundheit
          grosse
          gewicht
          name
          photos {
            gatsbyImageData
          }
        }
      }
    }
  }
`
