import React from "react"

import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"
import Title from "../components/title"

import { BsGenderFemale } from "react-icons/bs"
import { BsGenderMale } from "react-icons/bs"
import { GrStatusGood } from "react-icons/gr"
import { AiOutlineArrowsAlt } from "react-icons/ai"
import { GiWeight } from "react-icons/gi"

const Parents = (data) => {
  return (
    <Container fluid="sm" className="mb-3 mb-md-5">
      <Title title="Unsere Katzeneltern" />
      <Row className="d-flex justify-content-center">
        {data.parents.map((parent, i) => (
          <Col xs={12} md={6} className="my-2 mb-4">
            <Card key={i} style={{ background: "#FFF4E5" }} id={parent.node.name}>
              <Carousel interval={null}>
                {parent.node.photos.map((photo, i) => (
                  <Carousel.Item key={i}>
                    <GatsbyImage image={photo.gatsbyImageData} alt={parent.node.name} />
                  </Carousel.Item>
                ))}
              </Carousel>

              <CatName>{parent.node.name}</CatName>
              <Card.Body>
                <InfoGrid>
                  <InfoRow>
                    <InfoTop>Charakter</InfoTop>
                    <InfoBottom>{parent.node.charakter}</InfoBottom>
                  </InfoRow>

                  <InfoRow>
                    <InfoTop>Geschlecht {parent.node.geschlecht === 1 ? <BsGenderMale /> : <BsGenderFemale />} </InfoTop>
                    <InfoBottom>{parent.node.geschlecht === 1 ? "Männchen" : "Weibchen"}</InfoBottom>
                  </InfoRow>

                  <InfoRow>
                    <InfoTop>
                      Gesundheitszustand <GrStatusGood />
                    </InfoTop>
                    <InfoBottom>{parent.node.gesundheit}</InfoBottom>
                  </InfoRow>

                  <InfoRow>
                    <InfoTop>
                      Größe <AiOutlineArrowsAlt />
                    </InfoTop>
                    <InfoBottom>{parent.node.grosse} Zentimeter Kopf-Schwanz-Länge</InfoBottom>
                  </InfoRow>

                  <InfoRow>
                    <InfoTop>
                      Gewicht <GiWeight />
                    </InfoTop>
                    <InfoBottom>{parent.node.gewicht} Kilogramm</InfoBottom>
                  </InfoRow>
                </InfoGrid>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

const CatName = styled(Card.Title)`
  text-align: center;
  font-family: "Pacifico", cursive;
  font-size: 3rem !important;

  color: white;
  background: #ffb347;
  background: -webkit-linear-gradient(to bottom, #ffcc33, #ffb347);
  background: linear-gradient(to bottom, #ffcc33, #ffb347);

  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoGrid = styled.div`
  margin: 1rem auto;
`

const InfoRow = styled.div`
  text-align: center;
  margin: 1rem auto;
  letter-spacing: 1px;
`

const InfoTop = styled.div`
  font-family: "Pacifico", cursive;
`
const InfoBottom = styled.div``

export default Parents
