import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import styled from "styled-components"

import { StaticImage } from "gatsby-plugin-image"

const CatteryInfo = () => {
  return (
    <Container fluid="md" className="mt-5 mb-2">
      <TicaLogo>
        <StaticImage alt="tica logo" src="../images/Black-Globe-Only.png" />
      </TicaLogo>

      <Row className="justify-content-center px-1 px-sm-3 px-md-4 px-lg-5">
        <Col sm={10} md={8} className="my-2">
          <p>
            Unsere <TextDeco>Katzen</TextDeco> fühlen sich wohl. Man erkennt es ziemlich einfach am enstpannten Ausdruck und ruhigem Blick. In ihrem neuen Zuhause muß es denen genauso gut gehen. Wir achten darauf. Zugleich glauben wir aus tiefstem Herzen an <TextDeco>Menschen</TextDeco>.
          </p>
        </Col>
        <Col sm={10} md={8} className="my-2">
          <p>
            Unsere Katzen sind kein Produkt, sondern fühlende Lebewesen. Wir legen viel Wert auf ihre <TextDeco>Sozialisierung</TextDeco>. Wir lassen uns Zeit und arbeiten mit Geduld im Rhytmus der Natur. Unsere Katzen brauchen auch Eurerseits liebevolle Zuwendung.
          </p>
        </Col>
        <Col sm={10} md={8} className="my-2">
          <p>
            Wir achten sehr auf ihre <TextDeco>Gesundheit</TextDeco>. Die Katzen werden regelmäßig vom Tierarzt gründlich untersucht. Wir beobachten sie aufmerksam und achten auf symptomatische Anzeichen.
          </p>
        </Col>
        <Col sm={10} md={8} className="mt-2">
          <p>
            Wir bieten hochwertige und ausgewogene <TextDeco>Ernährung</TextDeco> mit hohem Proteingehalt und geringem Fett und Zuckergehalt. Unseres Futter enthält alle Nährstoffe und Bestandteile, die die Katze jeden Tag braucht.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

const TextDeco = styled.span`
  font-family: "Pacifico";
  color: #fbb034;
  letter-spacing: 1px;
`

const TicaLogo = styled.div`
  display: flex;
  margin: -0.75rem auto 2rem;
  max-width: 120px;
  height: 100%;

  @media (min-width: 720px) {
    margin: 0.25rem auto 2rem;
    max-width: 200px;
  }
`

export default CatteryInfo
