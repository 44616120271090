import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Hero = () => {
  return (
    <HeroWrapper>
      <StaticImage className="backgroundImage" alt="hero" src="../images/hero.jpg" objectFit="cover" objectPosition="50% 30%" />

      <Gradient />
      <Text>
        <h3>Wir sind</h3>
        <h2>Leopardis</h2>
      </Text>
    </HeroWrapper>
  )
}

const HeroWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.45));
`

const Text = styled.div`
  position: absolute;
  bottom: 10vw;
  right: 5vw;
  color: white;

  h2 {
    font-family: "Pacifico", cursive;
    font-size: 5.5em;
    letter-spacing: 1px;
    font-size: 8vw;
    line-height: 1vw !important;
  }

  h3 {
    font-family: "Arimo", sans-serif;
    text-align: center;
    font-size: 2.5vw;
    line-height: 1vw !important;
    margin-left: 8vw;
    color: #fbb034;
    color: #fbb034;
  }
`

export default Hero
